body {
  margin: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #F8F9FA;
  --bs-table-bg-type: "none"
}

.form-check-label {
  pointer-events: none;
}

.accordion-button {
  background: transparent !important;
}

.checkbox {
  border: 1px red solid;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

#read-only-checkbox {
  pointer-events: none !important;
}

.hero-title {
  width: 50%;
  margin: 0;
  margin-top: 36px;
  margin-bottom: 16px;
  font-size: 2rem;
}

.hero-image {
  width: 90%;
}

.feature-title {
    max-width: 70% !important;
  }

  .feature-title-7 {
    max-width: 85% !important;
  }

.feature-title-2 {
  width: 55%;
}

#responsive-image {
  margin-bottom: 32px !important;
}

.hero-subtitle {
  width: 47.5%;
  margin: 0;
  font-size: 1.1rem;
  margin-bottom: 12px;
  line-height: 1.3rem;
}

.feature-description {
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.2rem;
}

.feature-description-2 {
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.2rem;
  margin-bottom: 12px;
}

.feature-description-3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.2rem;
  margin-bottom: 12px;
}

#hoverLinks a:hover {
  color: #000000 !important; 
}

#hoverLinks p:hover {
  color: #000000 !important; 
}

@media only screen and (max-width: 1400px) {
  .feature-title {
    max-width: 80% !important;
  }

  .feature-title-2 {
    width: 70%;
  }

  .feature-description-2 {
    max-width: 95% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .hero-title {
    width: 75%;
  }

  .hero-subtitle {
    width: 65%;
  }

  .hero-image {
    width: 100%;
  }

  .feature-container {
    padding: 0 8px !important;
  }
}

@media only screen and (max-width: 995px) {
  .feature-description-2 {
    margin-bottom: 0;
  }

  .feature-description-3 {
    width: 80% !important;
  }

  .role-icon {
    margin-bottom: 16px !important;
  }

  .feature-title-2 {
    width: 85%;
  }
}

@media only screen and (max-width: 995px) {
  .hero-title {
    width: 85%;
  }

  .hero-subtitle {
    width: 80%;
  }
}

@media only screen and (max-width: 992px) {
  .feature-badge {
    margin-top: 24px;
  }

  .feature-title {
    text-align: center !important;
  }

  .feature-title-7 {
    text-align: center !important;
  }

  .feature-description-2 {
    text-align: center !important;
  }

  .feature-container {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .deneme {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 775px) {
  .hero-title {
    width: 90%;
  }

  .hero-subtitle {
    width: 90%;
  }

  .feature-description-3 {
    width: 100% !important;
  }

  .container-with-padding {
    padding: 0 12px !important;
  }
}

@media only screen and (max-width: 768px) {
  .role-container {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  #responsive-image {
    margin-bottom: 24px !important;
  }
}

@media only screen and (max-width: 550px) {
  .container-with-padding {
    padding: 0 !important;
  }

  .role-panel-section {
    padding: 16px 0 32px 0 !important;
  }

  .hero-title {
    font-size: 1.75rem;
  }
}


@media only screen and (max-width: 475px) {
  #badge-container {
    flex-direction: column !important;
  }

  #badge-1 {
    margin-right: 0 !important;
    margin-top: 12px !important;
  }
}